export const model = () => ([
  {
    model: 'name',
    title: 'Наименование',
    type: 'input'
  },
  {
    model: 'number',
    title: '№ баржи',
    required: true,
    noControls: true,
    type: 'number'
  }
])

export const source = () => ({
  name: null,
  number: null
})
