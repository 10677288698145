<template>
  <r-block v-loading="loading">
    <r-block
      col
      start
      no-padding
    >
      <r-button
        type="primary"
        :disabled="saveDisabled"
        @click="create"
      >
        Создать баржу
      </r-button>
      <r-button
        simple
        @click="$emit('close')"
      >
        Отмена
      </r-button>
    </r-block>
    <attributes-list
      :model="model"
      :source="source"
      is-editing
    />
  </r-block>
</template>

<script>
import { model, source } from './configs'
import attributesList from '@/components/globals/r-modal-elements/attributes-list'

export default {
  components: { attributesList },
  data() {
    return {
      loading: false,
      source: source(),
      model: model()
    }
  },
  computed: {
    saveDisabled() {
      return this.model?.some(a => {
        return a.required && !this.source[a.model]
      })
    }
  },
  methods: {
    async create() {
      try {
        this.loading = true

        this.source.source = 'dispatcher'

        await this.$store.dispatch('POST_REQUEST', {
          url: 'objectInfo/telemetry.barges',
          data: this.source
        })

        this.$emit('update-list', 'vehicle')
        this.$emit('close')
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
